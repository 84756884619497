.header{
    height: 250px;
    background-image: url(../images/banner.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 20px;
}

.header > img{
    height: 150px;
   
}