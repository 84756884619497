
.stepscontainer {
    max-width: 800px;
    background-color: white;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  
  @media (max-width: 700px) {
    .stepscontainer {
      width: 100%;
    }
  }
  
  /*TITULOS PARA MOBILE*/
  .stepsmobiletittlecontainer {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 15px;
    justify-content: center;
  }
  @media (min-width: 700px) {
    .stepsmobiletittlecontainer {
      display: none;
    }
  }
  
  .stepmobiletittle {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 5px;
    display: none;
  }
  
  .showstepmobiletittle {
    display: block !important;
    
  }
  
  /*NUMEROS Y TITULOS*/
  
  .stepsdesktopcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  @media (max-width: 700px) {
    .steptittle {
      display: none;
    }
  }
  
  .backButton{
    height: 22px;
    cursor: pointer;
  }
  
  .step {
    display: flex;
    margin: 0 5px;
    align-items: center;
  }
  
  .stepnumber {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .stepnumberactive {
    border: 1px solid var(--mainColor) !important;
    color: var(--mainColor) !important;
  }
  
  .stepnumbercomplete {
    background-color: var(--mainColor) !important;
    color: white !important;
    border: 1px solid var(--mainColor) !important;
  }
  
  .steptittle {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .steptittleactive {
    color: var(--mainColor) !important;
  }
  