.notificationPinOff_mainContainer{
    max-width: 600px;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #fbd76b;
}

@media (max-width: 700px) {
    .notificationPinOff_mainContainer {
      width: 100%;
    }
  }