.popUpContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 950000 !important;
  
  }

.justifyContentRight{
  width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 950000 !important;
    
}  
  
  .popUp {
    background-color: white;
    width: 500px;
    border-radius: 8px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    z-index: 40000000;
    margin-right: 50px;
    
  }
  
  .popUpTittle {
    text-align: center;
    margin: 25px 0;
    font-weight: 400;
    font-size: 20px;
  }
  
  .popUpMessage {
    text-align: center;
  }
  
  .closePopUpIcon {
    align-self: flex-end;
    cursor: pointer;
    height: 22px;
    margin-bottom: 20px;
  }

  .popUpButtom{
    margin-bottom: 8px;
    height: 40px;
    width: 100% !important;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px; 
    margin-top: 30px;
    background-color: var(--mainColor);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
 
  }

  .popUpButtomText{
    font-size: 18px !important;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 600;
  
  }
  


  /*NOTIFICATION CARD*/
  .notificationCardGreen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    max-width: 450px;
    position: absolute;
    top: 0;
    margin-top: 80px;
    margin-left: 100px;
    background-color: var(--mainColor);
    box-sizing: border-box;
    width: 80%
    
  }
  
  .notificationCardRed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    max-width: 400px;
    position: absolute;
    top: 0;
    margin-top: 60px;
    margin-left: 100px;
    background-color: red;
    box-sizing: border-box;
    width: 80%;
    
  }
  
  @media (max-width: 600px) {
    .notificationCardGreen {
      margin: 10px 5px;
    }
  
    .notificationCardRed {
      margin: 10px 5px;
    }
  }
  
  .notificationCardText{
    width: 80%
  }
  .notificationCardTittle {
    font-size: 16px;
    color: white;
  }
  
  .notificationCardMessage {
    font-size: 14px;
    width: 95%;
    color: white;
    box-sizing: border-box;
  }
  
  .notificationCardClose {
    height: 22px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  /*ERRORS*/
  .notificationError_container{
    border: 1px solid red;
    border-radius: 8px;
    background-color: rgb(250, 234, 234);
    padding: 10px;
    padding-left: 20px;
    width: 100%;
  }

  .notificationError_containerTitle{
    color: rgb(173, 2, 2);
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }

  .notificationError_errorList{
    padding: 10px;
  }

  .notificationError_error{
    color: rgb(173, 2, 2);
    margin-bottom: 10px;
    list-style-type: circle;
  }
  