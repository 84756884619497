@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

*{
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	border: none;
    outline: none;
    box-sizing: border-box;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: #F2F2F2; */
    background-color: white;
    font-family:'Ubuntu', sans-serif;
}

ul, li, h1, h2, h3, h4, h5, h6, p, button{
    margin: 0;
}


:root {
    --mainColor: #0f84e7;
  }