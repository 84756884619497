@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap);
*{
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	border: none;
    outline: none;
    box-sizing: border-box;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: #F2F2F2; */
    background-color: white;
    font-family:'Ubuntu', sans-serif;
}

ul, li, h1, h2, h3, h4, h5, h6, p, button{
    margin: 0;
}


:root {
    --mainColor: #0f84e7;
  }
.applicationFormContainer {
    background-color: white;
    
    margin: 10px auto;
    border-radius: 8px;
    width: 600px;
  }

  

  @media(max-width:750px){
    .applicationFormContainer {
      padding-bottom: 50px;
    }
  }

  @media(max-width:700px){
    .applicationFormContainer {
      width: auto;
      max-width: 600px !important;
    }
  }
  
  /*titulo h3*/
  .applicationFormContainer-tittle {
    padding: 20px 0px 0px 20px !important;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }

  .applicationFormContainer-tittle-noPadding {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }

  .inputSentACHToo{
    height: 16px;
    width: 16px;
  }



  .applicationFormContainer-statusNotMessage{
    margin-top: 25px;
    
  }

  .applicationFormContainer-tittle_green{
    color: var(--mainColor) !important;
    margin-left: 15px;
  }

  .applicationFormContainer-tittle_grey{
    color: #a3a3a3 !important;
  }



  .tabsMainContainer{
    width: 100%;
    /* background-color: white;
    border-radius: 8px;
    padding-top: 10px; */
  }
  
  /*Form mobile*/
  .form {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 8px;
    justify-items: center;
    padding: 20px 28px 20px 20px !important;
    box-sizing: border-box;
  }

  .campo::-webkit-datetime-edit-day-field:focus,
  .campo::-webkit-datetime-edit-month-field:focus,
  .campo::-webkit-datetime-edit-year-field:focus {
    background-color: #0f84e7;
    color: white;
    outline: none;
    -webkit-text-fill-color:white; 
  }
  
  .campo {
    -webkit-appearance: none !important;
  	-moz-appearance: none !important;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 14px !important;
    box-sizing: border-box;
    padding-left: 16px;
    /* border-radius: 5px; */
    background: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    -webkit-text-fill-color:rgba(0, 0, 0, 0.7); 
    -webkit-opacity: 1 !important;
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
   justify-content: center;
  }

  .campoSelect {
    -webkit-appearance: menulist !important;
  	-moz-appearance: none !important;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 14px !important;
    box-sizing: border-box;
    padding-left: 16px;
    /* border-radius: 5px; */
    background: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    -webkit-text-fill-color:rgba(0, 0, 0, 0.7); 
    -webkit-opacity: 1 !important;
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
   justify-content: center;
  }

  .campo::-webkit-contacts-auto-fill-button, 
  .campo::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
  }



  .campo::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

  .middle{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_Red{
    color: red;
  }

  .text_green{
    color:var(--mainColor);
  }

  /*
::-webkit-input-placeholder { color: rgb(172, 172, 172); } 

:-moz-placeholder { color: rgb(172, 172, 172); } 


::-moz-placeholder { color: rgb(172, 172, 172);} 

:-ms-input-placeholder { color: rgb(172, 172, 172);}

:focus::-webkit-input-placeholder{color:transparent;}
*/

.form_marginBotton{
  padding-bottom: 400px !important;
}


  .campoContainer{
    width: 100%;
    padding-top: 5px;
  }

  .checkCopyAddressInfo{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .checkCopyAddressInfoExternalForm{
    margin-bottom: 10px;
  }

  

  .labelApplicationForm{
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
  }

  .labelAuthorizationTerms{
    color: rgba(0, 0, 0, 0.7);
    font-size: 15px;
    font-weight: 500;
  }

  .errorMessage{
    font-size: 13px;
    color: red;
  }

 .disable-button{
   background-color: #8b8b8b !important;
 }
  .inputError{
    -webkit-appearance: none !important;
  	-moz-appearance: none !important;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 14px !important;
    box-sizing: border-box;
    padding-left: 16px;
    /* border-radius: 5px; */
    background: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
    border-color: red !important;
    -webkit-text-fill-color:rgba(0, 0, 0, 0.7); 
    -webkit-opacity: 1 !important;
    display: flex;
    align-items: center;
  }

  .inputError::-webkit-datetime-edit-day-field:focus,
  .inputError::-webkit-datetime-edit-month-field:focus,
  .inputError::-webkit-datetime-edit-year-field:focus {
    background-color: #0f84e7;
    color: white;
    outline: none;
    -webkit-text-fill-color:white; 
  }

  .inputErrorSelect{
  	-moz-appearance: none !important;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 14px !important;
    box-sizing: border-box;
    padding-left: 16px;
    /* border-radius: 5px; */
    background: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
    border-color: red !important;
    -webkit-text-fill-color:rgba(0, 0, 0, 0.7); 
    -webkit-opacity: 1 !important;
    display: flex;
    align-items: center;
  }

  .inputError::-webkit-inner-spin-button{
    -webkit-appearance: none;
}
  
  .campo:focus {
    outline: none;
    border: 1px solid var(--mainColor);
  }
  
  .submit {
    margin-top: 30px;
    background-color: var(--mainColor);
    color: white !important;
    cursor: pointer;
    -webkit-text-fill-color:white; 
  }

  .submitDisabled {
    margin-top: 30px;
    background-color: #bde6b3;
    color: white !important;
    -webkit-text-fill-color:white; 
  }

  .back{
    margin-top: 30px;
    cursor: pointer;
  }
  
  .fullwidth {
    grid-column: 1 / 3;
  }
  
  .mediowidth-left {
    grid-column: 1/2;
  }
  
  .mediowidth-right {
    grid-column: 2/3;
  }

  .containerBorderGrey{
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }
  
  .submit {
    padding: 0;
    width: 100%;
  }

  .spineerIntoInput{
    width: 100%;
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    margin-top: -48px;
    padding-right: 10px;
  }

  /*CURRENCY INPUT*/
  .inputCurrency{
    -webkit-text-fill-color:rgba(0, 0, 0, 0.7); 
    -webkit-opacity: 1 !important;
    color: rgba(0, 0, 0, 0.7);
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    width: 100%;
    background-color: white;
  }

  .inputCurrencySign{
    margin-right: 3px;
  }

  .inputCurrency::-webkit-inner-spin-button{
    -webkit-appearance: none;
}
  
  /*Form desktop*/
  @media (min-width: 600px) {
    .campo {
      height: 45px;
      font-size: 16px !important;
    }

    .inputError{
      height: 45px;
      font-size: 16px !important;
      border-color: red !important;

    }
  
    .applicationFormContainer {
      padding: 30px;
      margin: 10px auto;
      
    }

    .tabsContainer{
      display: flex;
      max-width: 100% !important;
      box-sizing: border-box;
      margin: 10px auto;
      padding: 0;
    }
    
    
  }

.applicationHasNotCoApplicant-message{
  text-align: center;
  margin-top: 15px;
}

.termsContainer{
  padding: 15px;
  font-size: 16px;
  color: #5d5d5d;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-top: 15px;
}

.termsContainerError{
  padding: 15px;
  font-size: 16px;
  color: #5d5d5d;
  border: 1px solid red;
  border-radius: 8px;
  margin-top: 15px;
}

  
/*STATUS*/
  

  .statusInformation_status{
    margin: 15px auto;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 20px;
  }

  .applicationFormContainer-title{
    font-weight: 700;
    font-size: 14px;
    color: #1f1e1e;
  }

  .margin-top30{
    margin-top: 30px;
  }

  .applicationFormContainer-title-value{
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .sheetContainer{
    background-color: white; 
    margin: 10px auto;
    max-width: 600px !important;
    padding: 0 40px 0 40px;
  }

  @media (max-width: 600px) {
    .sheetContainer{
      /* padding: 30px; */
      margin: 10px auto;
      padding: 0 20px 0 20px;
    }
  }

  .cardMessage{
    width: 100%;
    margin: 10px 0 10px 0;
    border: 1px solid rgb(211, 209, 209);
    border-radius: 8px;
    padding: 20px;
  }

  .cardMessage_error{
    width: 100%;
    margin: 10px 0 10px 0;
    border: 1px solid rgb(248, 125, 125);
    border-radius: 8px;
    padding: 20px;
    background-color: rgba(245, 220, 220, 0.842);
    color: red;
  }

  .statusMessage_error{
    color: rgb(88, 27, 27);
  }

  
  .statusHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }


  .statusTitle{
    font-weight: 700;
   
  }

  .statusMessage{
    color: #181818;
  }

  .statusMessage_date{
    color: #8b8b8b;
    font-size: 12px;
    margin-top: 10px;
  }
  

  
  /*TABS*/
  .applicationTabsContainer {
    width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    margin: 0 auto;
    /* background-color: white; */
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .applicationTabsContainer::-webkit-scrollbar {
    display: none;
  }

  .applicationTabsContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .applicationTabs {
    display: flex !important;
    justify-content: space-between;
    /*display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    list-style: none;
    justify-items: stretch;
    align-items: stretch;
    padding: 0;
    margin: 0 auto;*/
  }

  .applicationTabsProposals {
    display: flex !important;
  }



  .applicationTabsPinnacle{
    display: flex !important;
    
  }



  
  .applicationTab {
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    border-bottom: 3px solid white;
    cursor: pointer;
  }
  
  .applicationTabActive {
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    cursor: pointer;
    color: var(--mainColor);
    border-bottom: 3px solid var(--mainColor);
  }
  
  .applicationTab:hover {
    color: var(--mainColor);
    border-bottom: 3px solid var(--mainColor);
  }

  .applicationNumberTitle{
    padding-left: 20px;
    font-size: 24px;
  }




  /*spiner details application*/
  .spinnerDetails{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
  }

  .paddingZero{
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    padding-right: 8px !important;
  }


  /*PROMOTIONS*/

  .marginTop-10{
    margin-top: 10px;
  }

  .marginLeft-menos20{
    margin-left: -20px;
  }

 

  .promotionContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    box-sizing: border-box;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }


  .promotionCardNoEditable {
    padding: 10px;
    border: 1px solid #EBEBEB;
    /* max-width: 500px; */
    margin: 10px auto;
    border-radius: 8px;
    box-sizing: border-box;  
  }

  .promotionCard {
    padding: 10px;
    border: 1px solid #EBEBEB;
    /* max-width: 500px; */
    margin: 10px auto;
    border-radius: 8px;
    box-sizing: border-box;  
  }

  .promotionCard:hover {
    padding: 10px;
    border: 1px solid var(--mainColor);
    /* max-width: 500px; */
    margin: 10px auto;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--mainColor)1f;
    cursor: pointer;
  }

  .promotionCardActive{
    padding: 10px;
    border: 1px solid var(--mainColor);
    /* max-width: 500px; */
    margin: 10px auto;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--mainColor)1f;
    cursor: pointer;
  }
  
  .promotionName {
    font-size: 16px;
    padding: 5px 0;
    color: #333333;
  }
  
  .promotionContent {
    padding: 10px 0;
    padding-top: 0;
  }
  
  .promotionItem {
    margin: 5px 0;
  }
  
  .promotionTitleValue {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 5px;
  }
  
  .promotionValue {
    color: #141414;
    font-size: 16px;
  }
  
  .promotionButton {
    padding: 5px;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 120px;
  }

  .popUpLoadingPromotion{
    background-color:rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 700px;
    z-index: 250025;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 600px){
    .popUpLoadingPromotion{
      width: 100%;
    }
  }

  .popUpPromotionSaved{
    background-color:rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 700px;
    z-index: 250025;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 600px){
    .popUpPromotionSaved{
      width: 100%;
    }
  }

  .popUpPromotionSaved_Card{
    width: 80%;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .popUpPromotionSaved_Card-text{
    text-align: center;
    margin-top: 10px;
    
  }

  .popUpPromotionSaved_Card-button{
    background-color: var(--mainColor);
    padding: 10px 20px;
    border-radius: 4px;
    margin: 10px auto;
    color: white;
    cursor: pointer;

  }

  /*DOCUMENTS*/

.tabTittle{
  font-weight: 500;
  font-size: 20px;
 
  margin-bottom: 10px
}
.documentSection{
  width: 90%;
  margin: 0 auto;
 
}

.documentSectionContentMain{
  width: 100%;
}

.documentSectionContent{
  padding: 20px;
  width: 100%;
}

.documentSection-title{
  font-weight: 500px;
  margin-top: 30px;

}

 .selectFile{
   padding: 5px;
   background-color: var(--mainColor);
   width: 100px;
   position: relative;
   border-radius: 4px;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 10px;

 }

 .choseDocumentSection-span_filename{
   color: rgba(0, 0, 0, 0.7);
 }

 .labelSelectFile{
   color: white;
   text-align: center;
   font-size: 16px;
   cursor: pointer;
 }

 .choseDocumentSection{
   display: flex;
   align-items: center;
 }

 .documentChoseButtom{
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   cursor: pointer;
 }

.submitDisabled{
  background-color: #a4f28f !important;
  
}

 .document-card{
  padding: 10px 15px;
  border: 1px solid rgb(211, 209, 209);
  max-width: 100%;
  margin: 10px auto;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .document-card_info{
   width: 65%;
 }

 .document-card_info-span{
   display: block;
 }

 

 .document-card_info-type{
   font-size: 18px;
 }
 .document-card_info-fileName{
   font-size: 14px;
   color: rgba(0, 0, 0, 0.7);
   margin-bottom: 15px;
   overflow: hidden !important;
   margin-right: 10px;
 }

 .document-card_info-idAndDdate{
   display: flex;
 }

 .document-card_info-id{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
 }

 @media (max-width: 600px){
  .document-card_info-id{
    display: none;
  }

 
 }

 .document-card_info-date{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
 }

 .document-card_button{
  display: flex;
  justify-content: flex-end;
 

}

.document-card_button-dowload{
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--mainColor);
  cursor: pointer;
  background-color: white;
  margin-right: 5px;
}

.document-card_button-delete{
  border: 1px solid red;
  border-radius: 4px;
  padding: 5px 10px;
  color: red;
  cursor: pointer;
  background-color: white;
  margin-right: 5px;
}

.coapplicantNote{
  color: rgba(0, 0, 0, 0.705);
  padding: 15px 0;
  margin-left: 20px;
}

.selectCoapplicant{
  margin-left: 20px;
}

/*OTHER INCOMES*/

.incomeCard{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 8px;
  /* justify-items: center; */
  padding: 20px 28px 20px 20px !important;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 20px;
}

.addIncome_button{
  background-color: var(--mainColor);
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  left: 0;
  margin-left: 20px;
  cursor: pointer;
}

.addIncome_button-orange{
  background-color: #fac715!important;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  left: 0;
  margin-left: 20px;
  cursor: pointer;
}



.deleteIncome-icon{
  height: 22px;
  cursor: pointer;
  margin-left: 20px;
}

.addIncome_button-spacer{
  margin-top: 15px;
}

.deleteIncome-icon_container{
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

/*CONTRACT*/
.contract-headerContainer{
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.contract-headerContainer_buttonsContainer{
  display: flex;
  padding-left: 15px;
  padding-top: 10px;
}

.marginPinnacleContractButton{
  margin-top: 10px;
  margin-left: 10px;
}

.contract_header-editButtonContainer{
  display: flex;
}

.pinnacleResendContratLoadingButton{
  width: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pinnacleContract_resendContractSuccess{
  color: var(--mainColor);
  margin-left: 10px;
  margin-top: 10px;
}

.pinnacleContract_resendContractError{
  color: red;
  margin-left: 10px;
  margin-top: 10px;
}

.pinnacleContract_resendContractMessage{
  color: rgb(54, 54, 54);
  margin-left: 15px;
  margin-top: 10px;
}

.contract_header-editButton{
  padding: 6px 16px;
  color: white;
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background-color: var(--mainColor);
  cursor: pointer;
  margin-right: 15px;
}

.contract_header-editButton-white{
  padding: 6px 16px;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background-color: white ;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconLinkOutSide{
  height: 15px;
  margin-left: 8px;
}

/*SIGNING CONTRACT*/
.signingContract_sendingStatus{
  margin-left: 20px;
  color: rgb(39, 39, 39);
  margin-bottom: 10px;
}




/*PROPOSALS*/

.proposal-label_textRight{
  text-align: end;
}

.separator{
  height: 35px;
}

.noProposalText{
  text-align: center;
  margin-top: 20px;
}

.textColor_green{
  color: var(--mainColor);
}

.textColor_red{
  color: red;
}





.loadingPromotionsContainer{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.margin-left-10{
  margin-left: 10px;
}



/*Additional Income Source Types*/
.income_typeList_title{
  font-size: 13px;
  font-weight: 400;
}

.income_typeList{
  font-size: 12px;
  list-style: circle !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  font-weight: 400;
}






  
  
  

.stepscontainer {
    max-width: 800px;
    background-color: white;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  
  @media (max-width: 700px) {
    .stepscontainer {
      width: 100%;
    }
  }
  
  /*TITULOS PARA MOBILE*/
  .stepsmobiletittlecontainer {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 15px;
    justify-content: center;
  }
  @media (min-width: 700px) {
    .stepsmobiletittlecontainer {
      display: none;
    }
  }
  
  .stepmobiletittle {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 5px;
    display: none;
  }
  
  .showstepmobiletittle {
    display: block !important;
    
  }
  
  /*NUMEROS Y TITULOS*/
  
  .stepsdesktopcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  @media (max-width: 700px) {
    .steptittle {
      display: none;
    }
  }
  
  .backButton{
    height: 22px;
    cursor: pointer;
  }
  
  .step {
    display: flex;
    margin: 0 5px;
    align-items: center;
  }
  
  .stepnumber {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .stepnumberactive {
    border: 1px solid var(--mainColor) !important;
    color: var(--mainColor) !important;
  }
  
  .stepnumbercomplete {
    background-color: var(--mainColor) !important;
    color: white !important;
    border: 1px solid var(--mainColor) !important;
  }
  
  .steptittle {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .steptittleactive {
    color: var(--mainColor) !important;
  }
  
.popUpContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 950000 !important;
  
  }

.justifyContentRight{
  width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 950000 !important;
    
}  
  
  .popUp {
    background-color: white;
    width: 500px;
    border-radius: 8px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    z-index: 40000000;
    margin-right: 50px;
    
  }
  
  .popUpTittle {
    text-align: center;
    margin: 25px 0;
    font-weight: 400;
    font-size: 20px;
  }
  
  .popUpMessage {
    text-align: center;
  }
  
  .closePopUpIcon {
    align-self: flex-end;
    cursor: pointer;
    height: 22px;
    margin-bottom: 20px;
  }

  .popUpButtom{
    margin-bottom: 8px;
    height: 40px;
    width: 100% !important;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px; 
    margin-top: 30px;
    background-color: var(--mainColor);
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
 
  }

  .popUpButtomText{
    font-size: 18px !important;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 600;
  
  }
  


  /*NOTIFICATION CARD*/
  .notificationCardGreen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    max-width: 450px;
    position: absolute;
    top: 0;
    margin-top: 80px;
    margin-left: 100px;
    background-color: var(--mainColor);
    box-sizing: border-box;
    width: 80%
    
  }
  
  .notificationCardRed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    max-width: 400px;
    position: absolute;
    top: 0;
    margin-top: 60px;
    margin-left: 100px;
    background-color: red;
    box-sizing: border-box;
    width: 80%;
    
  }
  
  @media (max-width: 600px) {
    .notificationCardGreen {
      margin: 10px 5px;
    }
  
    .notificationCardRed {
      margin: 10px 5px;
    }
  }
  
  .notificationCardText{
    width: 80%
  }
  .notificationCardTittle {
    font-size: 16px;
    color: white;
  }
  
  .notificationCardMessage {
    font-size: 14px;
    width: 95%;
    color: white;
    box-sizing: border-box;
  }
  
  .notificationCardClose {
    height: 22px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  /*ERRORS*/
  .notificationError_container{
    border: 1px solid red;
    border-radius: 8px;
    background-color: rgb(250, 234, 234);
    padding: 10px;
    padding-left: 20px;
    width: 100%;
  }

  .notificationError_containerTitle{
    color: rgb(173, 2, 2);
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }

  .notificationError_errorList{
    padding: 10px;
  }

  .notificationError_error{
    color: rgb(173, 2, 2);
    margin-bottom: 10px;
    list-style-type: circle;
  }
  
.popUpContainer2 {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.NotificationPinnacleOff_notificationPinOff_mainContainer__19PCi{
    max-width: 600px;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #fbd76b;
}

@media (max-width: 700px) {
    .NotificationPinnacleOff_notificationPinOff_mainContainer__19PCi {
      width: 100%;
    }
  }
.Home_home__3T70t{
    padding-top: 20px;
}
.NoIdWarningMessage_NoIdWarningMessage__XjftW{
    max-width: 600px;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #fbd76b;
}
.Header_header__1R4rr{
    height: 250px;
    background-image: url(/static/media/banner.7641ae00.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 20px;
}

.Header_header__1R4rr > img{
    height: 150px;
   
}
